import { getAgreementDocumentSections, getAgreementDocuments } from 'application/adapters/agreements/agreementsAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import { useContext, useEffect, useState } from 'react';
import { Agreement, SectionContentContainer, Spinner } from 'ui/components';
import styles from './AgreementDocumentViewer.module.scss';
import { PageContext } from 'application/adapters/context/PageContext';

type AgreementDocumentViewerFeatureProps = {
	href: string;
	documentType: 'agreementDocumentViewer';
	content: {
		documentType: 'agreementDocumentViewer';
		properties: {
			agreementItem: {
				id: number;
				culture: string;
				name: string;
				url: string;
				documentType: string;
				template: string;
				createDate: string;
				sortOrder: string;
			};
		};
	};
};

function updateQueryString(params: Record<string, string | null>) {
	const searchParams = new URLSearchParams(window.location.search);

	Object.entries(params).forEach(([key, value]) => {
		if (value) {
			searchParams.set(key, value);
		} else {
			searchParams.delete(key);
		}
	});

	const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
	window.history.pushState({}, '', newUrl);
}

export const AgreementDocumentViewerFeature: React.VFC<AgreementDocumentViewerFeatureProps> = ({ content }) => {
	// Trimming the URL to get the path
	const pageContext = useContext(PageContext);
	const fullUrl = pageContext.url;
	function getPathFromUrl(url) {
		const urlObj = new URL(url);
		return urlObj.pathname;
	}
	const path = getPathFromUrl(fullUrl);

	const agreementId = content.properties.agreementItem.id;

	const [agreementMetaData, setAgreementMetaData] = useState(null);
	const { documents } = agreementMetaData || {};

	const [selectedAgreementDocumentId, setSelectedAgreementDocumentId] = useState(null);
	const currentAgreementDocument = documents?.find((doc) => doc.id === selectedAgreementDocumentId);
	const { title: documentTitle, teaser: documentTeaser, chapters } = currentAgreementDocument || {};

	const [selectedChapterId, setSelectedChapterId] = useState(null);
	const currentChapter = chapters?.find((chapter) => chapter.id === selectedChapterId);

	const [selectedSectionId, setSelectedSectionId] = useState(null);
	const [contentHtml, setContentHtml] = useState(null);

	const getQueryParams = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return {
			documentId: searchParams.get('documentId'),
			chapterId: searchParams.get('chapterId'),
			sectionId: searchParams.get('sectionId'),
		};
	};

	useEffect(() => {
		const handlePopState = () => {
			const { documentId, chapterId, sectionId } = getQueryParams();
			setSelectedAgreementDocumentId(documentId);
			setSelectedChapterId(chapterId);
			setSelectedSectionId(sectionId);
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	useEffect(() => {
		const { documentId, chapterId, sectionId } = getQueryParams();

		getAgreementDocuments(getCMSHostname(), agreementId)
			.then((response) => {
				setAgreementMetaData(response.data);
				setSelectedAgreementDocumentId(!documentId ? response.data.documents?.[0]?.id : documentId);
				setSelectedChapterId(!chapterId ? response.data.documents?.[0]?.chapters[0]?.id : chapterId);
				setSelectedSectionId(!sectionId ? response.data.documents?.[0]?.chapters[0]?.sections[0]?.id : sectionId);
			})
			.catch((error) => {
				logger.error(error);
			});
	}, [agreementId]);

	useEffect(() => {
		if (!agreementMetaData || selectedSectionId == null) return;

		setContentHtml(null);

		getAgreementDocumentSections(getCMSHostname(), selectedSectionId)
			.then((response) => {
				setContentHtml(response.data.sectionTexts.map((sectionText) => sectionText.html).join(''));
			})
			.catch((error) => {
				logger.error(error);
			});
	}, [agreementMetaData, selectedSectionId]);

	useEffect(() => {
		updateQueryString({
			documentId: selectedAgreementDocumentId,
			chapterId: selectedChapterId,
			sectionId: selectedSectionId,
		});
	}, [selectedAgreementDocumentId, selectedChapterId, selectedSectionId]);

	return (
		<>
			<SectionContentContainer>
				{!agreementMetaData || !currentAgreementDocument ? (
					<Spinner />
				) : (
					<article className={styles.documentWrapper}>
						{documentTitle && <h2 className={styles.documentHeading}>{documentTitle}</h2>}
						{documentTeaser && <p className={styles.documentTeaser}>{documentTeaser}</p>}
						<Agreement
							href={path}
							chapters={chapters}
							selectedChapterValue={selectedChapterId}
							onChapterChange={(chapterId) => {
								setSelectedChapterId(chapterId);
								setSelectedSectionId(chapters?.find((chapter) => chapter.id === chapterId)?.sections[0]?.id);
							}}
							sections={currentChapter?.sections || []}
							selectedSectionValue={selectedSectionId}
							onSectionChange={(sectionId) => {
								setSelectedSectionId(sectionId);
							}}
							contentHtml={contentHtml || ''}
							expandButtonText="Vis mere"
							collapseButtonText="Vis mindre"
						/>
					</article>
				)}
			</SectionContentContainer>
		</>
	);
};
