import { Accordion } from 'ui/components/3-organisms/Accordion';
import { shouldShowContent } from 'helpers/audiencehelper';
export const AccordionFeature: React.FC<Umbraco.Accordion> = ({ content }) => {

	const { accordionItems } = content?.properties ?? {};
	const { accordionTitle } = content?.properties ?? {};

	const show = shouldShowContent(content.properties.audiencePicker);

	const mappedAccordionItems: Models.AccordionItem[] = accordionItems.map(({ content, settings }) => {
		const { htmlContent, title, image, subTitle, imageName, imageTitle } = content?.properties ?? {};
		const { openByDefault } = settings?.properties ?? {};

		return {
			htmlContent,
			title,
			subTitle,
			image,
			imageName,
			imageTitle,
			openByDefault,
		};
	});

	return show ? <Accordion allowMultiple={false} accordionItems={mappedAccordionItems} accordionTitle={accordionTitle} />: <></>;
};
